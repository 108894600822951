<section id="liposomal-technology" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>LIPOZOMÁLNÍ TECHNOLOGIE</h1>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Novo C<sup>&reg;</sup> plus je lipozomální vitamin C nové generace s
            nejefektivnějším vstřebáváním. Představuje více než tradiční
            vitamíny C a vitamíny C s postupným uvolňováním.
          </p>
          <p>
            Lipozomální technologie je jedním z nejnovějších technologických
            objevů používaných lékařskými vědci k dodávání důležitých léků do
            orgánů. Je to proto, že tato technologie umožňuje cílené doručování
            v rámci organismu.
          </p>
          <p>Novo C je tak produkt s nejúčinnějším vstřebáváním!</p>
          <img
            src="/assets/images/pages/liposomal/liposzomalis.png"
            alt="Liposzómális"
          />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>CO JSOU TO LIPOZOMY?</h2>
          <img
            src="/assets/images/pages/liposomal/liposoma.png"
            alt="Liposzóma"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Lipozomální vitamin C není nic jiného než vitamin C a přírodní
            látky, tzv. chytrá kombinace fosfolipidů.
          </p>
          <p>
            Během výrobního procesu je vitamin C zabalen do obalu podobnému
            buněčné membráně a vytváří kuličky velikosti nano, lipozomy.
          </p>
          <p>
            Tento obal poskytuje ochrannou vrstvu pro vitamin C během trávícího
            procesu a umožňuje mu fúzovat s buňkami. S pomocí průtoku krve se
            dostává kdekoliv v našem těle a umožňuje efektivní absorpci a
            využití.
          </p>
          <h4>TENTO POVRCH UMOŽŇUJE VITAMINU C:</h4>
          <ul>
            <li>ochrannou vrstvu pro vitamin D během trávicího procesu</li>
            <li>fúzi s buňkami</li>
            <li>vysoce efektivní vstřebávání a využití</li>
          </ul>
          <h3>CO JE MEMBRÁNOVÁ FÚZE?</h3>
          <p>
            Buňky a lipozom jsou ohraničené stejnou dvojitou lipidovou vrstvou,
            která se navzájem přitahuje. Lipozomy dodávají léčivo do buněk
            membránovou fúzí, takže množství uvolnění není omezené.
          </p>
          <img src="/assets/images/pages/membran.png" alt="" />
          <p>
            Takže se nemusíme bát, že močí vyloučíme hodně vitaminu C, jelikož
            vitamin C se v těle využívá a nevylučuje močí. Takto má skutečně
            příznivý vliv na imunitní systém. Zároveň nezatěžuje zažívací
            systém: nedochází k pálení žáhy ani ke zvýšenému močení.
          </p>
          <h3>CO ZNAMENÁ BIO VYUŽITÍ?</h3>
          <p>
            Biologická dostupnost je rychlost a doba, jakou se účinná složka
            uvolňuje z přípravku a je dostupná v krevním řečišti. Data jsou
            vnesena do grafu a potom jsou porovnány oblasti pod křivkami.
          </p>
          <p>
            Biologická dostupnost lipozomálního vitaminu C je dvakrát větší než
            u běžných vitamínů C. To znamená, že za jednotku času může být
            vstřebáno větší množství, které může zůstat v krevním řečišti po
            delší dobu, takže plocha pod křivkou je 2 x větší než plocha
            tradičního vitaminu C.
          </p>
          <img src="/assets/images/pages/liposomal/lip2.png" alt="" />
          <h3>TAK EFEKTIVNÍ, ŽE STAČÍ NIŽŠÍ DÁVKA!</h3>
          <p>
            Další výhodou vynikajícího využití je, že dávku lze snížit, protože
            účinná látka se dostane tam, kde skutečně potřebuje pracovat. Již
            jedna kapsle Novo C <sup>&reg;</sup>300 mg denně přispívá k: podpoře
            imunitního systému, snížení únavy a vyčerpání, zdraví pokožky,
            dásní, kostí a chrupavek.
          </p>
          <h3>MALÉ, ALE OSVĚDČENÉ A VELMI SILNÉ!</h3>
          <p>
            Klinická studie z roku 2015 potvrdila, že Novo C
            <sup>&reg;</sup>plus je absorbován ve střevech a vstupuje do oběhu
            ve větší míře. Během dne je tedy tělu k dispozici větší množství
            vitaminu C, a tím se lépe přizpůsobuje zvýšeným potřebám těla.
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div> -->
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
