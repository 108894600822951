<section
  id="novo-c-immun"
  class="py-md-5"
  style="position: relative; z-index: 2"
>
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>NOVO C<sup>&reg;</sup> IMMUN</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-immun-img"
          src="/assets/images/pages/novo-c-immun/novo-c-immun.png"
          alt="Novo C immun"
        />
      </div>
      <div class="col-md-6">
        <h2>NOVO C<sup>&reg;</sup> IMMUN DOPORUČUJE SE UŽÍVAT PRO:</h2>
        <ul>
          <li>podporu fungování imunitního systému</li>
          <li>snížení míry únavy a vyčerpání</li>
          <li>normální funkci nervového systému</li>
          <li>ochranu buněk před oxidačním stresem</li>
          <li>udržení normálních psychických funkcí</li>
          <li>normální funkci energetického metabolismu</li>
          <li>
            správnou tvorbu kolagenu a tím udržení normálního stavu a funkce,
            kůž, zubů a dásní, kostí, chrupavek a cév
          </li>
          <li>zvýšení vstřebávání železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="table-div">
          <h2>ÚČINNÉ LÁTKY V 5 ML TEKUTINY</h2>
          <table>
            <tr>
              <th>Vitamin C</th>
              <td>1 000mg</td>
              <td>1 250%*</td>
            </tr>
          </table>
        </div>
        <div class="content">
          <p>
            * NRV% = referenční hodnoty denního příjmu pro dospělého
            <br />
            <b
              >Dalšími složkami jsou: čištěná voda, L - askorbát sodný, ethanol
              (12 m/m%), emulgátor (slunečnicový lecitin, polisorbát 80),
              regulátor kyselosti (monohydrát kyseliny citronové).</b
            >
          </p>
          <a
            [routerLink]="['/informace-o-nakupu']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMACE O NÁKUPU</a
          >
        </div>
      </div>
      <div class="col-lg-6 px-5">
        <img
          src="/assets/images/pages/novo-c-immun/strong-woman.png"
          class="px-5"
          alt="Strong woman"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-immun/novo-c-immun-title.png"
          alt="Novo C immun"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>GARANCE:</h2>
        <p>
          <strong
            >Novo C<sup>&reg;</sup> immun je vyroben z přírodního slunečnicového
            lecitinu, takže jej mohou konzumovat ti, kteří jsou na sóju
            citliví.</strong
          >
        </p>
        <p>
          Přípravek je vyroben podle normy EN ISO 9001 2008 , která splňuje
          výrobní požadavky EU.
        </p>
        <img
          src="/assets/images/pages/novo-c-immun/GMO-mentes.png"
          alt="GMO mentes"
          width="110px"
        />
        <img
          src="/assets/images/pages/novo-c-immun/GMO-free.png"
          alt="GMO mentes"
          width="110px"
        />
      </div>
      <div class="col-md-6">
        <h2>NÁVRHOVÁ DÁVKA:</h2>
        <p>
          <strong
            >U dospělých 1-2 x denně, měřeno na značku 5 ml (1000 mg vitaminu C)
            v zahrnuté dávce, užívané kdykoli během dne. Pokud jste citliví na
            chuť, doporučujeme konzumovat naměřenou dávku smíchanou se sladkou
            tekutinou (např. 100% džus).</strong
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE NOVO C<sup>&reg;</sup> IMMUN SPECIÁLNÍ?</h2>
        <p>
          Novo C<sup>&reg;</sup> immun obsahuje vitamin C v lipozomální formě.
          Díky inovativní technologii je vitamin C v přípravku obklopen přírodní
          fosfolipidovou vrstvou, která také tvoří naše buněčné membrány. Tato
          fosfolipidová vrstva poskytuje ochranný obal pro vitamin C během
          trávicího procesu a umožňuje vitaminu C snadnější vstup do buněk.
          Proto je obsah vitaminu C v přípravku Novo
          C<sup>&reg;</sup> immun ideální, lehce dostupný a dobře využitelný pro
          tělo.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENO LIPOZOMÁLNÍ TECHNOLOGIÍ</h2>
        <p>
          Lipozomální technologie je jedním z nejnovějších technologických
          objevů používaných lékařskými vědci k dodávání důležitých léků do
          správných orgánů.<br />Je to proto, že tato technologie umožňuje
          cílené doručování v rámci organismu.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JSOU TO LIPOZOMY?</h2>
        <p>
          Lipozomální vitamin C není nic jiného než vitamin C a přírodní látky,
          tzv. chytrá kombinace fosfolipidů.<br />Během výrobního procesu je
          vitamin C zabalen do obalu podobnému buněčné membráně a vytváří
          kuličky velikosti nano, lipozomy.<br />Tento obal poskytuje ochrannou
          vrstvu pro vitamin C během trávícího procesu a umožňuje mu fúzovat s
          buňkami. S pomocí průtoku krve se dostává kdekoliv v našem těle a
          umožňuje efektivní absorpci a využití.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>JAK SE LIPOZOMÁLNÍ VITAMIN C NOVO C PLUS VSTŘEBÁVÁ?</h2>
        <p>
          Měkká želatinová tobolka s vitaminem C se rozpouští v žaludku a
          uvolňují se z ní lipozomy. Některé z nich se připojí na buňky žaludku
          a pomocí membránové fúze zvyšují obsah vitaminu C v krvi a rychle ho
          dodávají tělu. Většina lipozomu se dostane do tenkého střeva, odkud
          poskytují postupné a dlouhotrvající doplnění vitaminu C do orgánů a
          systémů. Lipozomální roztok nám proto poskytuje vitamin C ve větších
          množstvích, nepřetržitě a kratší cestou.
        </p>
        <!-- <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JE MEMBRÁNOVÁ FÚZE?</h2>
        <p>
          Buňky a lipozom jsou ohraničené stejnou dvojitou lipidovou vrstvou,
          která se navzájem přitahuje. Lipozomy dodávají léčivo do buněk
          membránovou fúzí, takže množství uvolnění není omezené.
        </p>
        <img src="" alt="" />
        <p>
          Takže se nemusíme bát, že močí vyloučíme hodně vitaminu C, jelikož
          vitamin C se v těle využívá a nevylučuje močí. Takto má skutečně
          příznivý vliv na imunitní systém. Zároveň nezatěžuje zažívací systém:
          nedochází k pálení žáhy ani ke zvýšenému močení.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLOUHODOBÝ ÚČINEK!</h2>
        <p>Vitamin C se vstřebává nepřetržitě během minimálně 12 hodin.</p>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a
          [routerLink]="['/']"
          routerLinkActive="router-link-active"
          class="btn"
          style="margin-bottom: 2rem ;"
          >Další informace</a
        >
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">BALENÍ A DOPORUČENÁ SPOTŘEBITELSKÁ CENA:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> immun 136 ml</th>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-immun/immun-jobb.png"
          alt="Novo C immun"
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <!-- <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div> -->
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
