import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-novo-c-komplex",
  templateUrl: "./novo-c-komplex.component.html",
  styleUrls: ["./novo-c-komplex.component.scss"],
})
export class NovoCKomplexComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Novo C® komplex");
  }
}
