<section id="novo-c-komplex" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> komplex</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-komplex-img"
          src="/assets/images/pages/novo-c-komplex/novo-c-komplex.png"
          alt="Novo C Komplex"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> komplex Doporučuje se pro:</h2>
        <ul>
          <li>správné fungování imunitního systému</li>
          <li>
            Vitamin D pomáhá vstřebávat vápník a tím přispívá k pevnosti kostí a
            zdravého svalstva
          </li>
          <li>
            Zinek stabilizuje hladinu cukru v krvi a reguluje rychlost
            metabolismu
          </li>
          <li>chrání buňky před oxidačním stresem</li>
          <li>udržení normálních psychických funkcí</li>
          <li>
            správnou tvorbu kolagenu a tím udržení normálního stavu a funkce
            kůže, zubů, dásní, kostí, chrupavek a cév
          </li>
          <li>zvýšenou absorpci železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> komplex je lipozomální vitamin C nové
            generace s nejefektivnějším vstřebáváním. Představuje více než
            tradiční vitamíny C a vitamíny C s postupným uvolňováním.<sup
              >1.</sup
            >
          </p>
          <a [routerLink]="['/liposomalna-technologia']" class="btn">Ďalši</a>
        </div>
      </div>
      <div class="col-md-5">
        <div class="img">
          <img
            src="/assets/images/pages/novo-c-komplex/d-vitamin.png"
            alt="D-vitamin"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> komplex je lipozomální vitamin C nové
            generace s nejefektivnějším vstřebáváním. Představuje více než
            tradiční vitamíny C a vitamíny C s postupným uvolňováním.<sup
              >1.</sup
            >
          </p>
          <a
            [routerLink]="['/liposomalna-technologia']"
            routerLinkActive="router-link-active"
            class="btn"
            >Ďalši</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-komplex/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section> -->

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="table-div">
          <h2>ÚČINNÉ LÁTKY V JEDNÉ KAPSLI</h2>
          <table>
            <tr>
              <th>Vitamin C</th>
              <td>300 mg</td>
              <td>375%*</td>
            </tr>
            <tr>
              <th>Šípkový extrakt</th>
              <td>20 mg</td>
              <td>**</td>
            </tr>
            <tr>
              <th>Citrusové bioflavonoidy</th>
              <td>20 mg</td>
              <td>**</td>
            </tr>
            <tr>
              <th>Vitamin D3</th>
              <td>25 µg (1 000 NE)</td>
              <td>500%</td>
            </tr>
            <tr>
              <th>Zinek</th>
              <td>10 mg</td>
              <td>100%</td>
            </tr>
          </table>
        </div>
        <div class="content">
          <p>
            * NRV% = referenční hodnoty denního příjmu pro dospělé
            <br />
            ** NRV není stanoveno
            <br />
            <b
              >Pomocnými látkami jsou: želatina, nerafinovaný organický sezamový
              olej, čištěná voda, smáčedlo (glycerol), emulgátor (sójový
              lecitin), askorbát vápenatý, včelí vosk, barvivo (lykopen),
              askorbylpalmitát, cholekalciferol.</b
            >
          </p>
          <a
            [routerLink]="['/informace-o-nakupu']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMACE O NÁKUPU</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <img
          src="/assets/images/pages/novo-c-komplex/novo-c-komplex.png"
          alt="Novo C komplex"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-komplex/novo-c-komplex-title.png"
          alt="Novo C komplex"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>GARANCE:</h2>
        <p>
          <strong
            >Vyrobeno výhradně z geneticky nemodifikovaných přísad, s barvivem
            (lykopen) z přírodního rajčatového extraktu a bez konzervačních
            látek.</strong
          >
        </p>
        <p>
          Neobsahuje tedy žádné zbytečné přísady, které by tělo zatěžovaly.
          Moderní výrobní proces produktu splňuje požadavky správné výrobní
          praxe (GMP).
        </p>
        <img
          src="/assets/images/pages/novo-c-komplex/GMO-mentes.png"
          alt="GMO mentes"
          width="110px"
        />
        <img
          src="/assets/images/pages/novo-c-komplex/GMO-free.png"
          alt="GMO mentes"
          width="110px"
        />
      </div>
      <div class="col-md-6">
        <h2>DOPORUČENÉ DÁVKOVÁNÍ</h2>
        <p>
          <b
            >Dospělí 1 - 2 kapsle denně ideálně ráno a zapít dostatečným
            množstvím tekutin. Od 8 let 1 kapsle denně. Doporučená denní dávka
            se může užívat současně. Měkká želatinová kapsle umožňuje přesné
            dávkování, pohodlné, bezpečné a snadné polykání.</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE Novo C<sup>&reg;</sup> komplex SPECIÁLNÍ?</h2>
        <p>
          Novo C<sup>&reg;</sup> komplex je lipozomální vitamin C nové generace
          s nejefektivnějším vstřebáváním. Představuje více než tradiční
          vitamíny C a vitamíny C s postupným uvolňováním. kOMPLEX Novo
          C<sup>&reg;</sup> obsahuje také vitamin D3 a zinek v lipozomální
          formě. Takže toto trojité složení pomáhá podporovat imunitní systém.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ VITAMIN D?</h2>
        <p>
          Vitamin D je biologicky produkován v těle slunečním světlem a lze jej
          také najít v různých potravinách. Během zimních měsíců není naše tělo
          schopné produkovat dostatek vitaminu D, a proto je důležité jej
          doplňovat. Nedostatek vitaminu D může vést ke ztrátě kostní hmoty a
          narušení rovnováhy vápníku. Vitamin D pomáhá při vstřebávání vápníku,
          čímž přispívá k pevnosti kostí a zdravému svalstvu.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE DŮLEŽITÝ ZINEK?</h2>
        <p>
          Zinek stabilizuje hladinu cukru v krvi. Pokud jej v těle nemáme
          dostatek, inzulinová odpověď klesá a hladina cukru v krvi může
          kolísat.
        </p>
        <p>
          Zinek ovlivňuje rychlost metabolismu. Když množství zinku v těle
          klesá, metabolismus se zpomaluje. Zinek je důležitým regulátorem mnoha
          genetických aktivit. Buňky jsou instruovány geny, aby vykonávaly různé
          funkce a musí tyto genetické pokyny interpretovat ve všech situacích.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ÚLOHA VITAMINU D A ZINKU:</h2>
        <p>
          V posledních letech byly objeveny účinky vitaminu D na imunitní systém
          - kromě mnoha dalších prospěšných vlastností. Vitamin D a Zinek
          přispívají k normálnímu fungování imunitního systému. Zinek také
          přispívá k udržení normální duševní funkce a ochraně buněk před
          oxidačním stresem.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JSOU TO LIPOZOMY?</h2>
        <p>
          Lipozomální vitamin C není nic jiného než vitamin C a přírodní látky,
          tzv. chytrá kombinace fosfolipidů.<br />
          Během výrobního procesu je vitamin C zabalen do obalu podobnému
          buněčné membráně a vytváří kuličky velikosti nano, lipozomy.<br />
          Tento obal poskytuje ochrannou vrstvu pro vitamin C během trávícího
          procesu a umožňuje mu fúzovat s buňkami. S pomocí průtoku krve se
          dostává kdekoliv v našem těle a umožňuje efektivní absorpci a využití.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>JAK SE LIPOZOMÁLNÍ VITAMIN C VSTŘEBÁVÁ?</h2>
        <p>
           Měkká želatinová tobolka s vitaminem C se rozpouští v žaludku a
          uvolňují se z ní lipozomy. Některé z nich se připojí na buňky žaludku
          a pomocí membránové fúze zvyšují obsah vitaminu C v krvi a rychle ho
          dodávají tělu. Většina lipozomu se dostane do tenkého střeva, odkud
          poskytují postupné a dlouhotrvající doplnění vitaminu C do orgánů a
          systémů. Lipozomální roztok nám proto poskytuje vitamin C ve větších
          množstvích, nepřetržitě a kratší cestou.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JE MEMBRÁNOVÁ FÚZE?</h2>
        <p>
          Buňky a lipozom jsou ohraničené stejnou dvojitou lipidovou vrstvou,
          která se navzájem přitahuje. Lipozomy dodávají léčivo do buněk
          membránovou fúzí, takže množství uvolnění není omezené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže se nemusíme bát, že močí vyloučíme hodně vitaminu C, jelikož
          vitamin C se v těle využívá a nevylučuje močí. Takto má skutečně
          příznivý vliv na imunitní systém. Zároveň nezatěžuje zažívací systém:
          nedochází k pálení žáhy ani ke zvýšenému močení.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK EFEKTIVNÍ, ŽE STAČÍ NIŽŠÍ DÁVKA!</h2>
        <p>
          Další výhodou vynikajícího využití je, že dávku lze snížit, protože
          účinná látka se dostane tam, kde skutečně potřebuje pracovat. Již
          jedna kapsle Novo C<sup>&reg;</sup> komplex 300 mg denně přispívá k:
          účinné podpoře imunitního systému, snížení únavy a vyčerpání, zdraví
          pokožky, dásní, kostí a chrupavek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVĚDČENÉ A VELMI SILNÉ!</h2>
        <p>
          Klinická studie z roku 2015 potvrdila, že Novo C<sup>&reg;</sup>
          komplex je absorbován ve střevech a vstupuje do oběhu ve větší míře.
          Během dne je tedy tělu k dispozici větší množství vitaminu C, a tím se
          lépe přizpůsobuje zvýšeným potřebám těla.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLOUHODOBÝ ÚČINEK!</h2>
        <p>Vitamin C se vstřebává nepřetržitě během minimálně 12 hodin.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENO S PŘÍRODNÍMI SLOŽKAMI!</h2>
        <p>Obsahuje přírodní výtažek ze šípku a citrusové bioflavonoidy.</p>
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C Complex soft tob. CZK 30</th>
              <td>336 Kč</td>
            </tr>
            <tr>
              <th>Novo C Complex soft tob. CZK 60</th>
              <td>514 Kč</td>
            </tr>
            <tr>
              <th>Novo C Complex soft tob. CZK 90</th>
              <td>700 Kč</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-komplex/novo-c-komplex.png"
          alt="Novo C komplex"
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <!-- <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div> -->

    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
