<section id="novo-c-plus" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> plus</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/novo-c-plus/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> plus se doporučuje užívat pro:</h2>
        <ul>
          <li>podporu fungování imunitního systému</li>
          <li>snížení míry únavy a vyčerpání</li>
          <li>normální funkci nervového systému</li>
          <li>ochranu buněk před oxidačním stresem</li>
          <li>udržení normálních psychických funkcí</li>
          <li>normální funkci energetického metabolismu</li>
          <li>
            pro správnou tvorbu kolagenu a tím udržení normálního stavu a funkce
            kůže, zubů, dásní, kostí, chrupavek a cév.
          </li>
          <li>zvýšení vstřebávání železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> plus je lipozomální vitamin C nové generace s
            nejefektivnějším vstřebáváním. Představuje více než tradiční
            vitamíny C a vitamíny C s postupným uvolňováním.
          </p>
          <p>
            Lipozomální technologie je jedním z nejnovějších technologických
            objevů používaných lékařskými vědci k dodávání důležitých léků do
            orgánů. Je to proto, že tato technologie umožňuje cílené doručování
            v rámci organismu.
          </p>
          <p>
            Novo C<sup>&reg;</sup> plus je tak produkt s nejúčinnějším
            vstřebáváním!
          </p>
          <a
            [routerLink]="['/liposomalna-technologia']"
            routerLinkActive="router-link-active"
            class="btn"
            >DALŠÍ</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-plus/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="table-div">
          <h2>Účinné látky v jedné kapsli</h2>
          <table>
            <tr>
              <th>C-vitamin</th>
              <td>300mg</td>
              <td>375%*</td>
            </tr>
            <tr>
              <th>Šípkový extrakt</th>
              <td>20mg</td>
              <td>**</td>
            </tr>
            <tr>
              <th>Citrusové bioflavonoidy</th>
              <td>20mg</td>
              <td>**</td>
            </tr>
          </table>
        </div>
        <div class="content">
          <p>
            * NRV% = referenční hodnoty denního příjmu pro dospělé
            <br />
            ** NRV není stanoven
            <br />
            <b
              >Pomocnými látkami jsou: želatina, nerafinovaný organický sezamový
              olej, čištěná voda, smáčedlo (glycerol), emulgátor (sójový
              lecitin), včelí vosk, přírodní barvivo (lykopen).</b
            >
          </p>
          <a
            [routerLink]="['/informace-o-nakupu']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMACE O NÁKUPU</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <img
          src="/assets/images/pages/novo-c-plus/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-plus/novo-c-plus-title.png"
          alt="Novo C plus"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>GARANCE:</h2>
        <p>
          <b
            >Vyrobeno výhradně z geneticky nemodifikovaných přísad, s barvivem
            (lykopen) z přírodního rajčatového extraktu a bez konzervačních
            látek.</b
          >
        </p>
        <p>
          Neobsahuje tedy žádné zbytečné přísady, které by tělo zatěžovaly.
          Moderní výrobní proces produktu splňuje požadavky správné výrobní
          praxe (GMP).
        </p>
        <img
          src="/assets/images/pages/novo-c-plus/GMO-mentes.png"
          alt="GMO mentes"
          width="110px"
        />
        <img
          src="/assets/images/pages/novo-c-plus/GMO-free.png"
          alt="GMO mentes"
          width="110px"
        />
      </div>
      <div class="col-md-6">
        <h2>DOPORUČENÉ DÁVKOVÁNÍ:</h2>
        <p>
          <b>
            Kapsle Novo C<sup>&reg;</sup> plus by měly být užívány nejlépe ráno
            s dostatečným množstvím tekutin.
          </b>
        </p>
        <p>
          <b>
            Doporučená denní dávka je 1 - 4 tobolky pro dospělé a 1 - 2 tobolky
            pro děti od 6 let. Doporučenou denní dávku lze užít současně. Měkká
            želatinová tobolka poskytuje přesné dávkování, pohodlné, bezpečné a
            snadné polykání.
          </b>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE Novo C<sup>&reg;</sup> plus SPECIÁLNÍ?</h2>
        <p>
          Novo C<sup>&reg;</sup> plus je lipozomální vitamin C nové generace s
          nejefektivnějším vstřebáváním. Představuje více než tradiční vitamíny
          C a vitamíny C s postupným uvolňováním.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENO LIPOZOMÁLNÍ TECHNOLOGIÍ</h2>
        <p>
          Lipozomální technologie je jedním z nejnovějších technologických
          objevů používaných lékařskými vědci k dodávání důležitých léků do
          správných orgánů.
          <br />
          Je to proto, že tato technologie umožňuje cílené doručování v rámci
          organismu.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2>CO JSOU TO LIPOZOMY?</h2>
        <p>
          Lipozomální vitamin C není nic jiného než vitamin C a přírodní látky,
          tzv. chytrá kombinace fosfolipidů.
          <br />
          Během výrobního procesu je vitamin C zabalen do obalu podobnému
          buněčné membráně a vytváří kuličky velikosti nano, lipozomy.
          <br />
          Tento obal poskytuje ochrannou vrstvu pro vitamin C během trávícího
          procesu a umožňuje mu fúzovat s buňkami. S pomocí průtoku krve se
          dostává kdekoliv v našem těle a umožňuje efektivní absorpci a využití.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          JAK SE LIPOZOMÁLNÍ VITAMIN C Novo C<sup>&reg;</sup> plus VSTŘEBÁVÁ?
        </h2>
        <p>
          Měkká želatinová tobolka s vitaminem C se rozpouští v žaludku a
          uvolňují se z ní lipozomy. Některé z nich se připojí na buňky žaludku
          a pomocí membránové fúze zvyšují obsah vitaminu C v krvi a rychle ho
          dodávají tělu. Většina lipozomu se dostane do tenkého střeva, odkud
          poskytují postupné a dlouhotrvající doplnění vitaminu C do orgánů a
          systémů. Lipozomální roztok nám proto poskytuje vitamin C ve větších
          množstvích, nepřetržitě a kratší cestou.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JE MEMBRÁNOVÁ FÚZE?</h2>
        <p>
          Buňky a lipozom jsou ohraničené stejnou dvojitou lipidovou vrstvou,
          která se navzájem přitahuje. Lipozomy dodávají léčivo do buněk
          membránovou fúzí, takže množství uvolnění není omezené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže se nemusíme bát, že močí vyloučíme hodně vitaminu C, jelikož
          vitamin C se v těle využívá a nevylučuje močí. Takto má skutečně
          příznivý vliv na imunitní systém. Zároveň nezatěžuje zažívací systém:
          nedochází k pálení žáhy ani ke zvýšenému močení.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK EFEKTIVNÍ, ŽE STAČÍ NIŽŠÍ DÁVKA!</h2>
        <p>
          Další výhodou vynikajícího využití je, že dávku lze snížit, protože
          účinná látka se dostane tam, kde skutečně potřebuje pracovat. Již
          jedna kapsle Novo C <sup>&reg;</sup>plus 300 mg denně přispívá k:
          účinné podpoře imunitního systému, snížení únavy a vyčerpání, zdraví
          pokožky, dásní, kostí a chrupavek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVĚDČENÉ A VELMI SILNÉ!</h2>
        <p>
          Klinická studie z roku 2015 potvrdila, že Novo C<sup>&reg;</sup> plus
          je absorbován ve střevech a vstupuje do oběhu ve větší míře. Během dne
          je tedy tělu k dispozici větší množství vitaminu C, a tím se lépe
          přizpůsobuje zvýšeným potřebám těla.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLOUHODOBÝ ÚČINEK!</h2>
        <p>Vitamin C se vstřebává nepřetržitě během minimálně 12 hodin.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENO S PŘÍRODNÍMI SLOŽKAMI!</h2>
        <p>Obsahuje přírodní výtažek ze šípku a citrusové bioflavonoidy.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a
          [routerLink]="['/informace-o-nakupu']"
          routerLinkActive="router-link-active"
          class="btn"
          >DALŠÍ INFORMACE</a
        >
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C Plus soft case CZK 30</th>
              <td>293 KČ</td>
            </tr>
            <tr>
              <th>Novo C Plus soft case CZK 60</th>
              <td>481 KČ</td>
            </tr>
            <tr>
              <th>Novo C Plus soft case CZK 60</th>
              <td>654 KČ</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otevřená, randomizovaná, srovnávací studie k určení biologické
            dostupnosti doplňku vitaminu C Novo C<sup>&reg;</sup> plus C u
            zdravých dobrovolníků. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. Nová perorální lipozomální forma vitaminu C:
            vlastnosti a biologická dostupnost. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
