import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-absorption',
  templateUrl: './absorption.component.html',
  styleUrls: ['./absorption.component.scss']
})
export class AbsorptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
