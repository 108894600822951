<section id="zinc" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>ZINEK</h1>
          <img src="/assets/images/pages/main/zinc.png" alt="zinc" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Zinek je minerál, který se doporučuje konzumovat v malém množství,
            ale je nezbytný pro normální fungování našeho těla a imunitního
            systému.
          </p>
        </div>
        <div class="content">
          <p>
            Doporučená denní referenční hodnota pro zinek je 10 mg pro
            dospělého. Zinek se nachází v potravinách bohatých na bílkoviny,
            jako je mléko, vejce, maso, chřest a ryby.
          </p>
          <p>
            Během tepelného zpracování jídla může dojít ke ztrátě významné části
            zinku. Nebo ti kteří vedou aktivní životní styl, mohou tento
            důležitý minerál ztratit silným pocením, a proto je důležité ho
            doplňovat.
          </p>
          <h3>Zinek se podílí na:</h3>
          <ul>
            <li>udržování normální acidobazické rovnováhy</li>
            <li>normálním metabolismu sacharidů</li>
            <li>udržování normálního duševního fungování</li>
            <li>normální syntéze DNA</li>
            <li>udržování normální plodnosti a reprodukce</li>
            <li>normálním metabolismu makroživin</li>
            <li>normálním metabolismu mastných kyselin</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
