<section id="d-vitamin" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Vitamin D</h1>
          <img
            src="/assets/images/pages/d-vitamin/d-vitamin.png"
            alt="d-vitamin"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            V posledních letech byly objeveny účinky vitaminu D na imunitní
            systém - kromě mnoha dalších prospěšných vlastností.
          </p>
        </div>
        <div class="content">
          <p>
            Vitamin D je biologicky produkován v těle slunečním světlem a lze
            jej také najít v různých potravinách. Příkladem je mléko, losos,
            olej z tresčích jater, vejce a houby.
          </p>
          <p>
            Naše tělo však není schopno v zimních měsících produkovat dostatek
            vitaminu D, a proto je důležité jej doplňovat. Nedostatek vitaminu D
            může vést ke ztrátě kostní hmoty a narušení rovnováhy vápníku.
          </p>
          <h3>Vitamin D přispívá k:</h3>
          <ul>
            <li>normálnímu vstřebávání/využití vápníku a fosforu</li>
            <li>udržení normální hladiny vápníku v krvi</li>
            <li>udržení zdravých kostí</li>
            <li>udržení zdravé svalové funkce</li>
            <li>udržení zdravých zubů</li>
            <li>normální funkci imunitního systému</li>
            <li>buněčnému dělení</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
