<section id="hero">
  <img src="/assets/images/pages/main/hero-new-3.webp" alt="Novo C" />
</section>

<section id="scientific-background" class="mt-4 mb-5">
  <div class="home-title text-center">
    <span> Vědecké pozadí </span>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div id="liposomal-technology">
          <a [routerLink]="['/liposomalna-technologia']">
            <img
              src="/assets/images/pages/main/liposomal-technology.jpg"
              alt="Liposzómális technológia"
              id="liposomal-technology-img"
            />
            <h2>LIPOZOMÁLNÍ TECHNOLOGIE</h2>
          </a>
          <p>
            Novo C<sup>&reg;</sup> plus je lipozomální vitamin C nové generace s
            nejefektivnějším vstřebáváním. Představuje více než tradiční
            vitamíny C a vitamíny C s postupným uvolňováním.
          </p>
          <p>
            Lipozomální technologie je jedním z nejnovějších technologických
            objevů používaných lékařskými vědci k dodávání důležitých léků do
            orgánů. Je to proto, že tato technologie umožňuje cílené doručování
            v rámci organismu.
          </p>
          <p>Novo C je tak produkt s nejúčinnějším vstřebáváním!</p>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="absorption">
          <a [routerLink]="['/vstrebavani']">
            <img
              src="/assets/images/pages/main/absorption.jpg"
              alt="Felszívódás"
              id="absorption-img"
            />
            <h2>VSTŘEBÁVÁNÍ</h2>
          </a>
          <p>
            Z vitamínů je vitamín C jedním z hlavních spojenců našeho imunitního
            systému. Navzdory tomu ním plýtváme? Odpověď je ANO.
          </p>
          <p>
            Od objevu vitaminu C bylo provedeno několik studií, aby se zjistilo,
            jak účinně jej tělo vstřebává.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="products" class="my-5">
  <div class="products-container">
    <div class="row">
      <div class="col-md-4">
        <div id="novo-c-multivitamin" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-multivitamin']">
            <img
              src="/assets/images/pages/main/novo-c-multivitamin.png"
              alt="Novo C multivitamin"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-multivitamin']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>multivitamin</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-multivitamin']"
              >čtěte víc</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-plus" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus']">
            <img
              src="/assets/images/pages/main/novo-c-plus.png"
              alt="Novo C plus"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus']">čtěte víc</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-komplex" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-komplex']">
            <img
              src="/assets/images/pages/main/novo-c-komplex.png"
              alt="Novo C Komplex"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-komplex']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>komplex</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-komplex']">čtěte víc</a>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div id="novo-c-plus-liquid" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']">
            <img
              src="/assets/images/pages/main/novo-c-plus-1000mg.png"
              alt="Novo C plus 1000 mg"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus 1000 mg</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
              >čtěte víc</a
            >
          </div>
        </div>
      </div> -->
      <div class="col-md-4">
        <div id="novo-c-kids" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-kids']">
            <img
              src="/assets/images/pages/main/novo-c-kids.png"
              alt="Novo C kids"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-kids']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>kids</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-kids']">čtěte víc</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="csuszda">
  <div class="img-box">
    <a routerLink="/nase-produkty/novo-c-kids">
      <img src="/assets/images/pages/main/csuszda.webp" alt="Kids" />
      <div class="content">
        <h3>Novo C<sup>&reg;</sup> dětský lipozomální vitamin C od 1 roku.</h3>
        <h4>
          Novo C<sup>&reg;</sup> lipozomální vitamin C je nová generace vitaminu
          C.
        </h4>
        <p>
          Dokáže více jako tradiční vitamíny C a vitamíny C s postupným
          uvolňováním.<sup>1.</sup>
        </p>
        <p>
          Tekuté složení umožňuje jednoduché dávkování a jeho lahodná malinová
          příchuť se rychle stane oblíbenou u dětí.
        </p>
      </div>
    </a>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1>SLOŽKY</h1>
        <h2>
          VYROBENO S PŘÍRODNÍMI SLOŽKAMI.<br />OBSAHUJE PŘÍRODNÍ ŠÍPKOVÝ
          EXTRAKT,<br />CITRUSOVÉ BIOFLAVONOIDY, LIPOZOMÁLNÍ VITAMIN D A
          LIPOZOMÁLNÍ ZINEK.
          <!-- ZDROJE VITAMINU C: ŠÍPEK A CITRUSOVÉ BIOFLAVONOIDY<br />POKUD VITAMIN C NESTAČÍ: D3 A ZINEK -->
        </h2>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/vitamin-c']">
            <img
              src="/assets/images/pages/main/c-vitamin.png"
              alt="C-vitamin"
            />
            <h3>C-VITAMIN</h3>
            <p>
              Z vitamínů je vitamín C jedním z hlavních spojenců našeho
              imunitního systému Navzdory tomu ním plýtváme?
            </p>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/vitamin-d']">
            <img
              src="/assets/images/pages/main/d-vitamin.png"
              alt="D-vitamin"
            />
            <h3>D3-VITAMIN</h3>
            <p>
              V posledních letech byly mimo jiné objeveny účinky vitamínu D na
              imunitní systém.
            </p>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/zinek']">
            <img src="/assets/images/pages/main/zinc.png" alt="ZN-vitamin" />
            <h3>ZINEK</h3>
            <p>
              Zinek je minerál, který se doporučuje konzumovat v malém množství,
              ale je nezbytný pro normální fungování našeho těla a imunitního
              systému.
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otevřená, randomizovaná, srovnávací studie ke stanovení biologické
            dostupnosti vitaminu C Novo C<sup>&reg;</sup> Plus u zdravých
            dobrovolníků. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al.Nová perorální lipozomální forma vitaminu C:
            vlastnosti a biologická dostupnost. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back"></div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
