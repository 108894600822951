import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navbarRightPos: string;
  isDropdownsVisible = new Array();
  isNavbarBg: boolean;
  isHideSubmenu: boolean;

  constructor(
    private router: Router
  ) {
    if (window.innerWidth > 767) {
      this.navbarRightPos = '-50%';
    } else {
      this.navbarRightPos = '-100%';
    }
    this.isDropdownsVisible = ['none', 'none'];
  }

  ngOnInit(): void {
    this.isHideSubmenu = true;
  }

  navigate(url: string): void {
    this.isDropdownsVisible[0] = 'none';
    this.isDropdownsVisible[1] = 'none';
    if (url !== '/') {
      this.toggle();
    }
    this.router.navigate([url]);
  }

  toggle() {
    console.log('lefut');
    
    if (window.innerWidth > 767) {
      if (this.navbarRightPos === '-50%') {
        this.navbarRightPos = '0';
      } else {
        this.navbarRightPos = '-50%';
      }
    } else {
      if (this.navbarRightPos === '-100%') {
        this.navbarRightPos = '0';
      } else {
        this.navbarRightPos = '-100%';
      }
    }
  }

  dropddownToVisible(num: number) {
    if (window.innerWidth > 1200) {
      this.isDropdownsVisible[num] = 'block';
    }
  }

  dropddownToHidden(num: number) {
    if (window.innerWidth > 1200) {
      this.isDropdownsVisible[num] = 'none';
    }
  }

  toggleDropdown(num: number) {
    if (window.innerWidth <= 1200) {
      if (this.isDropdownsVisible[num] === 'none') {
        this.isDropdownsVisible[num] = 'block';
      } else {
        this.isDropdownsVisible[num] = 'none';
      }
    }
  }

}
