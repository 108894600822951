import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pharmacy-finder',
  templateUrl: './pharmacy-finder.component.html',
  styleUrls: ['./pharmacy-finder.component.scss']
})
export class PharmacyFinderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
