import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-novo-c-multivitamin',
  templateUrl: './novo-c-multivitamin.component.html',
  styleUrls: ['./novo-c-multivitamin.component.scss']
})
export class NovoCMultivitaminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
