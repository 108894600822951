<section id="purchase-information" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>INFORMACE O NÁKUPU</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/purchase-information/novo-c-termekek-new-3.webp"
          alt="Novo C Plus"
        />
      </div>
      <div class="col-md-6" style="margin-top: 2rem">
        <h2></h2>
      </div>
    </div>
  </div>
</section>

<section id="where" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>KDE ZÍSKAT NOVO C?</h2>
        <p>
          Výrobky jsou dostupné v lékárnách, bylinných specializovaných
          prodejnách a drogeriích.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C Plus soft case CZK 30</th>
              <td>293 KČ</td>
            </tr>
            <tr>
              <th>Novo C Plus soft case CZK 60</th>
              <td>481 KČ</td>
            </tr>
            <tr>
              <th>Novo C Plus soft case CZK 60</th>
              <td>654 KČ</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/main/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/main/novo-c-komplex.png"
          alt="Novo C komplex"
        />
      </div>
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C Complex soft tob. CZK 30</th>
              <td>336 Kč</td>
            </tr>
            <tr>
              <th>Novo C Complex soft tob. CZK 60</th>
              <td>514 Kč</td>
            </tr>
            <tr>
              <th>Novo C Complex soft tob. CZK 90</th>
              <td>700 Kč</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C plus 1000 mg vit.C liquid 150 ml CZK</th>
              <td>443 KČ</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus-liquid/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C kids 120 ml CZK</th>
              <td>363 KČ</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-kids/novo-c-kids.png"
          alt="Novo C kids"
        />
      </div>
    </div>
  </div>
</section>

<section id="info" class="pb-3">
  <div class="container">
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
