<section id="koszonjuk">
  <div class="container">
    <div class="wrapper">
      <div class="box">
        <h1>Děkuji za Váš dopis!</h1>
        <p>
          Velmi děkujeme za Váš dopis, náš kolega Vás bude brzy kontaktovat!
        </p>
        <div class="text-center">
          <a routerLink="/" class="btn">Zpět na hlavní stránku</a>
        </div>
      </div>
    </div>
  </div>
</section>
